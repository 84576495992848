var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.provider)?_c('b-modal',{attrs:{"title":_vm.text,"centered":"","hide-footer":""},model:{value:(_vm.openedModal),callback:function ($$v) {_vm.openedModal=$$v},expression:"openedModal"}},[_c('validation-observer',{ref:"providerValidationModal"},[_c('b-form',{staticClass:"form"},[_c('b-form-group',{attrs:{"label":"Naziv"}},[_c('validation-provider',{attrs:{"name":"naziv","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Naziv"},model:{value:(_vm.provider.name),callback:function ($$v) {_vm.$set(_vm.provider, "name", $$v)},expression:"provider.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1112062980)})],1),_c('b-form-group',{attrs:{"label":"Naslov"}},[_c('validation-provider',{attrs:{"name":"naslov","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Naslov"},model:{value:(_vm.provider.address),callback:function ($$v) {_vm.$set(_vm.provider, "address", $$v)},expression:"provider.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3947175286)})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label":"Poštna številka"}},[_c('validation-provider',{attrs:{"name":"poštna številka","rules":"required|min_value: 1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"Poštna številka"},model:{value:(_vm.provider.post_number),callback:function ($$v) {_vm.$set(_vm.provider, "post_number", _vm._n($$v))},expression:"provider.post_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1698150172)})],1)],1),_c('b-col',{attrs:{"cols":"7"}},[_c('b-form-group',{attrs:{"label":"Pošta"}},[_c('validation-provider',{attrs:{"name":"pošta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Pošta"},model:{value:(_vm.provider.post),callback:function ($$v) {_vm.$set(_vm.provider, "post", $$v)},expression:"provider.post"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3863813562)})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Spletna stran"}},[_c('b-form-input',{attrs:{"placeholder":"Spletna stran"},model:{value:(_vm.provider.website),callback:function ($$v) {_vm.$set(_vm.provider, "website", $$v)},expression:"provider.website"}})],1),_c('b-form-group',{attrs:{"label":"Telefonska številka"}},[_c('validation-provider',{attrs:{"name":"telefonska_stevilka","rules":"phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Telefonska številka"},model:{value:(_vm.provider.telephone_number),callback:function ($$v) {_vm.$set(_vm.provider, "telephone_number", $$v)},expression:"provider.telephone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,837738686)})],1),_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Email"},model:{value:(_vm.provider.email),callback:function ($$v) {_vm.$set(_vm.provider, "email", $$v)},expression:"provider.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3126377961)})],1),_c('h5',{staticClass:"mb-1 mt-2"},[_vm._v("Kontaktna oseba")]),_c('b-form-group',{attrs:{"label":"Ime in priimek / naziv"}},[_c('b-form-input',{attrs:{"placeholder":"Ime in priimek / naziv"},model:{value:(_vm.provider.contact_person.name),callback:function ($$v) {_vm.$set(_vm.provider.contact_person, "name", $$v)},expression:"provider.contact_person.name"}})],1),_c('b-form-group',{attrs:{"label":"Telefonska številka"}},[_c('validation-provider',{attrs:{"name":"telefonska številka","rules":"phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Telefonska številka"},model:{value:(_vm.provider.contact_person.telephone_number),callback:function ($$v) {_vm.$set(_vm.provider.contact_person, "telephone_number", $$v)},expression:"provider.contact_person.telephone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,4234752250)})],1),_c('b-form-group',{attrs:{"label":"Email"}},[_c('b-form-input',{attrs:{"placeholder":"Email"},model:{value:(_vm.provider.contact_person.email),callback:function ($$v) {_vm.$set(_vm.provider.contact_person, "email", $$v)},expression:"provider.contact_person.email"}})],1),(_vm.type === 'add')?_c('b-form-group',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.validationFormAddProvider}},[_vm._v("Dodaj")])],1):_vm._e(),(_vm.type === 'edit')?_c('b-form-group',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.validationFormEditProvider}},[_vm._v("Uredi")])],1):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }