<template>
    <div>
        <b-overlay :show="showLoader">
            <b-card>
                <b-row>
                    <b-col cols="12" sm="6" class="text-center text-sm-left">
                        <b-button variant="primary" @click="onAddProvider" v-if="$hasPermissions($permissions.EditService)">Dodaj pondudnika storitve</b-button>
                    </b-col>
                </b-row>

                <Table ref="table" class="mt-1" :fields="fields" route="/api/management/v1/service/provider/" :fromElastic="false">
                    <template #cell(actions)="row" v-if="$hasPermissions($permissions.EditService)">
                        <div v-if="Object.keys(row.item).length !== 0" class="button-grid">
                            <b-button class="mr-1" variant="warning" @click="onEditProvider(row.item)"><fa icon="edit"/></b-button>
                            <b-button variant="danger" @click="deleteProvider(row.item.id)"><fa icon="trash"/></b-button>
                        </div>
                    </template>
                </Table>
            </b-card>
        </b-overlay>
        <provider-modal ref="addProviderModal" type="add" :provider="providerToAdd" @success="resetProvider(providerToAdd); getItems()" />
        <provider-modal ref="editProviderModal" type="edit" :provider="providerToEdit" @success="resetProvider(providerToEdit); getItems()" />
    </div>
</template>
<script>
    import {
        BButton,
        BOverlay,
        BCard,
        BRow,
        BCol
    } from 'bootstrap-vue'
    import ProviderModal from './ProviderModal.vue'
    import Table from '@/views/Components/Table'
    export default {
        components: {
            ProviderModal,
            BOverlay,
            BCard,
            BRow,
            BCol,
            BButton,
            Table
        },
        data() {
            return {
                showLoader: false,
                data: [],
                pageOptions: [10, 20, 50, 100],
                pagination: {current_page: 1, per_page: 10, total_items: 0},
                filters: {},
                fields: [
                    { key: 'name', label: 'Naziv', sortable: true, class: 'text-center' },
                    { key: 'email', label: 'Email', sortable: true, class: 'text-center' },
                    { key: 'telephone_number', label: 'Telefonska številka', sortable: true, class: 'text-center' }
                ],
                providerToAdd: {
                    name: '',
                    address: '',
                    post_number: 0,
                    post: '',
                    website: '',
                    telephone_number: '',
                    email: '',
                    contact_person: {
                        name: '',
                        telephone_number: '',
                        email: ''
                    }
                },
                providerToEdit: null
            }
        },
        methods: {
            getItems() {
                setTimeout(function() {
                    this.$refs.table.search()
                }.bind(this), 1000)
            },
            resetProvider(provider) {
                provider.name = ''
                provider.address =  ''
                provider.post_number =  0
                provider.post =  ''
                provider.website =  ''
                provider.telephone_number =  ''
                provider.email =  ''
                provider.contact_person.name =  ''
                provider.contact_person.telephone_number =  ''
                provider.contact_person.email =  ''
            },
            onAddProvider() {
                this.$refs.addProviderModal.openModal()
            },
            onEditProvider(provider) {
                this.providerToEdit = JSON.parse(JSON.stringify(provider))
                this.$refs.editProviderModal.openModal()
            },
            deleteProvider(id) {
                this.$bvModal.msgBoxConfirm('Prosimo potrdite, da želite izbrisati tega ponudnika storitev.', {
                    size: 'sm',
                    okTitle: 'Da',
                    cancelTitle: 'Ne',
                    hideHeaderClose: false,
                    okVariant: 'danger',
                    cancelVariant: 'outline-secondary',
                    centered: true
                }).then(value => {
                    if (value) {
                        const thisIns = this
                        thisIns.showLoader = true
                        this.$http.delete(`/api/management/v1/service/provider/${id}`).then(function() {
                            thisIns.$printSuccess('Ponudnik storitev je bil uspešno izbrisan')
                        }).catch(function(error) {
                            thisIns.$printError(error.response.data)
                        }).finally(function() {
                            thisIns.$refs.table.search()
                            thisIns.showLoader = false
                        })
                    }
                })
            }
        },
        mounted() {
            if (this.$hasPermissions(this.$permissions.EditService)) {
                this.fields.push({ key: 'actions', label: '', class: 'text-center' })
            }
        }
    }
</script>

<style scoped>
.button-grid {
  display: flex;
  justify-content: center;
}
</style>