<template>
    <b-modal v-model="openedModal" :title="text" centered hide-footer v-if="provider">
        <validation-observer ref="providerValidationModal">
            <b-form class="form">
                <b-form-group label="Naziv">
                    <validation-provider #default="{ errors }" name="naziv" rules="required">
                        <b-form-input v-model="provider.name" placeholder="Naziv"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Naslov">
                    <validation-provider #default="{ errors }" name="naslov" rules="required">
                        <b-form-input v-model="provider.address" placeholder="Naslov"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-row>
                    <b-col cols="5">
                        <b-form-group label="Poštna številka">
                            <validation-provider #default="{ errors }" name="poštna številka" rules="required|min_value: 1000">
                                <b-form-input type="number" v-model.number="provider.post_number" placeholder="Poštna številka"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="7">
                        <b-form-group label="Pošta">
                            <validation-provider #default="{ errors }" name="pošta" rules="required">
                                <b-form-input v-model="provider.post" placeholder="Pošta"/>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-form-group label="Spletna stran">
                    <b-form-input v-model="provider.website" placeholder="Spletna stran"/>
                </b-form-group>
                <b-form-group label="Telefonska številka">
                    <validation-provider #default="{ errors }" name="telefonska_stevilka" rules="phone_number">
                        <b-form-input v-model="provider.telephone_number" placeholder="Telefonska številka"/>
                        <small class="text-danger">
                            {{ errors[0] }}
                        </small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Email">
                    <validation-provider #default="{ errors }" name="email" rules="required|email">
                        <b-form-input v-model="provider.email" placeholder="Email"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <h5 class="mb-1 mt-2">Kontaktna oseba</h5>
                <b-form-group label="Ime in priimek / naziv">
                    <b-form-input v-model="provider.contact_person.name" placeholder="Ime in priimek / naziv"/>
                </b-form-group>
                <b-form-group label="Telefonska številka">
                    <validation-provider #default="{ errors }" name="telefonska številka" rules="phone_number">
                        <b-form-input v-model="provider.contact_person.telephone_number" placeholder="Telefonska številka"/>
                        <small class="text-danger">
                            {{ errors[0] }}
                        </small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Email">
                    <b-form-input v-model="provider.contact_person.email" placeholder="Email"/>
                </b-form-group>
                <b-form-group v-if="type === 'add'" class="text-right">
                    <b-button variant="secondary" @click="validationFormAddProvider">Dodaj</b-button>
                </b-form-group>
                <b-form-group v-if="type === 'edit'" class="text-right">
                    <b-button variant="secondary" @click="validationFormEditProvider">Uredi</b-button>
                </b-form-group>
            </b-form>
        </validation-observer>
    </b-modal>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {BModal, BFormGroup, BFormInput, BButton, BRow, BCol, BForm} from 'bootstrap-vue'
    import { required, maxValue, minValue, max, email, PhoneNumber, min, atLeastOneDigit, atLeastOneUppercase } from '@validations'
    export default {
        components: {
            BModal,
            BFormGroup,
            BFormInput,
            BButton,
            BRow,
            BCol,
            BForm,
            ValidationProvider,
            ValidationObserver
        },
        props: {
            type: {
                type: String,
                reqired: true
            },
            provider: {
                type: Object,
                reqired: false
            }
        },
        data() {
            return {
                openedModal: false,
                text: 'Dodaj',
                required,
                maxValue,
                minValue,
                max,
                email,
                PhoneNumber,
                min,
                atLeastOneDigit,
                atLeastOneUppercase
            }
        },
        methods: {
            openModal() {
                if (this.type === 'edit') {
                    this.text = 'Uredi'
                }
                this.openedModal = true
            },
            validationFormAddProvider() {
                this.$refs.providerValidationModal.validate().then(success => {
                    if (success) {
                        this.addProvider()
                    }
                })
            },
            async validationFormEditProvider() {
                this.$refs.providerValidationModal.validate().then(success => {
                    if (success) {
                        this.editProvider()
                    }
                })
            },
            addProvider() {
                const thisIns = this
                this.$http.put('/api/management/v1/service/provider/', this.provider)
                    .then(function() {
                        thisIns.$printSuccess('Ponudnik storitev je bil dodan')
                        thisIns.openedModal = false
                        thisIns.$emit('success')
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    })
            },
            editProvider() {
                const thisIns = this
                this.$http.patch(`/api/management/v1/service/provider/${this.provider.id}`, this.provider)
                    .then(function() {
                        thisIns.$printSuccess('Ponudnik storitev je bil uspešno urejen')
                        thisIns.openedModal = false
                        thisIns.$emit('success')
                    }).catch(function(error) {
                        thisIns.$printError(error.message)
                    })
            }
           
        }
    }
</script>
